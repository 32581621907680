import React, { Component } from 'react';
import { string, bool, arrayOf } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';
import { required, bookingDatesRequired, composeValidators } from '../../util/validators';
import { START_DATE, END_DATE } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, PrimaryButton, FieldTFDateInput, FieldTimeSlot, FieldNumOfPersonsInput } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';

import css from './BookingDatesForm.css';

const identity = v => v;

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);

    this.timeSlotId = uniqueId('time-slot-');
    this.numberOfPersonsId = uniqueId('number-of-persons-');

    this.state = { focusedInput: null };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  componentDidMount(){
    let startDateObj = document.getElementById(`${this.props.formId}.bookingStartDate`);
    let endDateObj = document.getElementById(`${this.props.formId}.bookingEndDate`);

    endDateObj.style.color = "white";
    endDateObj.style.fontSize = "0px";
    endDateObj.onfocus = () => {
      startDateObj.focus();
    }
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    const startTime = e[this.timeSlotId] && e[this.timeSlotId] !== "Invalid date"
      ? ",   " + e[this.timeSlotId]
      : ",   " + moment.utc(this.props.availabilityTimes.availableFromTimestamp * 1000).format("hh:mm A");
    const eP = { ...e };
    eP.timeSlot = startTime;

    const { startDate, endDate } = e.bookingDates || {};
    if (!startDate) {
      e.preventDefault();
      this.setState({ focusedInput: START_DATE });
    } else if (!endDate) {
      e.preventDefault();
      this.setState({ focusedInput: END_DATE });
    } else {
      this.props.onSubmit(eP);
    }
  }

  render() {
    let { rootClassName, className, price: unitPrice, availabilityTimes, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }
    /* eslint-disable no-unused-vars */
    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        availabilityTimes={availabilityTimes}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitPrice,
            unitType,
            values,
            timeSlots,
            availabilityTimes,
            fetchTimeSlotsError,
          } = fieldRenderProps;
          const { startDate, endDate } = values && values.bookingDates ? values.bookingDates : {};
          const startTime = values && values[this.timeSlotId] && values[this.timeSlotId] !== "Invalid date"
                            ? ",   " + values[this.timeSlotId]
                            : ",   " + moment.utc(availabilityTimes.availableFromTimestamp * 1000).format("hh:mm A");
          if(!values[this.timeSlotId] || values[this.timeSlotId] === "Invalid date"){
            values[this.timeSlotId] = moment.utc(availabilityTimes.availableFromTimestamp * 1000).format("hh:mm A");
          }

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({ id: 'BookingDatesForm.bookingEndTitle' });
          const requiredMessage = intl.formatMessage({ id: 'BookingDatesForm.requiredDate' });
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidStartDate',
          });
          const endDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidEndDate',
          });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.timeSlotsError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          let numOfPersons = 1;
          if(typeof document !== "undefined") {
            console.log('numberOfPersonsId : ' + this.numberOfPersonsId);
            let numOfPersonsObj = document.getElementById(this.numberOfPersonsId);
            if(!numOfPersonsObj){
              numOfPersonsObj = document.getElementsByClassName("numberOfPersons")[0];
            }

            if (numOfPersonsObj) {
              const numOfPersonsVal = numOfPersonsObj.value;
              if (/^[0-9]+$/.test(numOfPersonsVal)) {
                numOfPersons = parseInt(numOfPersonsVal, 10);
              }
            }
          }

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  unitPrice,
                  startDate,
                  endDate,

                  // NOTE: If unitType is `line-item/units`, a new picker
                  // for the quantity should be added to the form.
                  quantity: numOfPersons,
                  startTime: startTime,
                  timeSlot: startTime
                }
              : null;
          const bookingInfo = bookingData ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} />
            </div>
          ) : null;

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };
          /* eslint-disable no-unused-vars */
          const now = moment();
          const today = now.startOf('day').toDate();
          const tomorrow = now
            .startOf('day')
            .add(1, 'days')
            .toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(today, dateFormatOptions);
//          const endDatePlaceholderText =
//            endDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);
          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              {timeSlotsError}
              <FieldTFDateInput
                className={css.bookingDates}
                name="bookingDates"
                unitType={unitType}
                startDateId={`${formId}.bookingStartDate`}
                startDateLabel={bookingStartLabel}
                startDatePlaceholderText={startDatePlaceholderText}
                endDateId={`${formId}.bookingEndDate`}
                endDateLabel={bookingEndLabel}
                endDatePlaceholderText=""
                timeSlotId={this.timeSlotId}
                numberOfPersonsId={this.numberOfPersonsId}
                focusedInput={this.state.focusedInput}
                availabilityTimes={availabilityTimes}
                onFocusedInputChange={this.onFocusedInputChange}
                format={identity}
                timeSlots={timeSlots}
                useMobileMargins
                validate={composeValidators(
                  required(requiredMessage),
                  bookingDatesRequired(startDateErrorMessage, endDateErrorMessage)
                )}
              />
              <FieldTimeSlot
                id={this.timeSlotId}
                ref={this.timeSlotDropdown}
                name={this.timeSlotId}
                label="Choose start time"
                startTimestamp={availabilityTimes.availableFromTimestamp}
                endTimestamp={availabilityTimes.availableTillTimestamp}
                useMobileMargins
              />
              <br/>
              <FieldNumOfPersonsInput
                id={this.numberOfPersonsId}
                ref={this.numberOfPersonsInput}
                type="number"
                pattern="[0-9]*"
                name={this.numberOfPersonsId}
                label="Choose team size"
                value="1"
                min="1"
                max="100"
                placeholder="8"
                useMobileMargins
              />
              {bookingInfo}
              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit">
                  <FormattedMessage id="BookingDatesForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
